<template>
  <div v-if="show" class="mb-8">
    <div v-if="true" class="flex items-center flex-col">
      <img class="w-45 h-45 -mb-12" src="/small_icon_MM_fuction_email.png" />
    </div>
    <div
      class="rounded-xl pt-4 pb-8"
      :class="{
        'bg-black bg-opacity-10 px-5 pt-4 md:pt-8 md:pb-10': true,
        'bg-lightgray shadow px-6 pt-4 md:pt-12 md:pb-14': !true,
      }"
    >
      <div class="relative md:max-w-screen-sm mx-auto">
        <div v-if="!signedUp">
          <template v-if="true">
            <h2 class="text-center headline-xs !font-normal mb-4 mt-6">
              Få Mandag Morgens overskrifter direkte i din mail.
            </h2>
            <h3 class="text-center headline-xxs !font-normal">
              Tilmeld dig nyhedsbrevet nu
            </h3>
          </template>
          <template v-else>
            <img
              :src="envelopeIconUrl"
              alt=""
              class="absolute top-[10%] right-[45px] h-10 hidden xl:block"
            />
            <img
              :src="arrowIconUrl"
              alt=""
              class="absolute top-[22%] right-[75px] h-20 hidden xl:block"
            />
            <div
              class="flex flex-col md:flex-row items-center justify-start mb-2 md:mb-4 xl:mr-48"
            >
              <img
                :src="promoEmailIconUrl"
                :alt="$t('Email')"
                class="w-12 h-12 md:w-5 md:h-5 drop-shadow-xl md:drop-shadow-none -mt-10 mb-4 md:mt-0 md:mb-0 inline-block md:mr-1"
              />
              <span
                class="text-blue font-medium font-sans md:label-m md:uppercase"
              >
                {{ $t('newsletterTitle1') }}
              </span>
            </div>
            <h2 class="list-title-l text-center md:text-left md:mr-12 xl:mr-48">
              {{ $t('newsletterTitle2') }}
            </h2>
          </template>
          <NewsletterForm
            :button-text="buttonText"
            :newsletter="newsletter"
            @signed-up="hide"
          />
        </div>
        <div v-else class="flex flex-col items-center space-y-4 md:m-10">
          <img :src="checkCircleIconUrl" class="w-8 h-8 m-3" />
          <h3 class="list-title-s">
            {{ $t('ThanksForSigningUp') }}
          </h3>
          <p class="body-m text-center">{{ $t('newslettterSignedUpText') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import envelopeIconUrl from '~/assets/icons/envelope.svg?url'
import arrowIconUrl from '~/assets/icons/curly-arrow.svg?url'
import checkCircleIconUrl from '~/assets/icons/check-circle.svg?url'
import promoEmailIconUrl from '~/assets/icons/promoicon_email.svg?url'

const { nwCookie } = useNwCookie()
const userStore = useUserStore()

const props = defineProps<{
  buttonText?: string
  alwaysShow?: boolean
  newsletter?: string | number
}>()

const signedUp = ref(false)

const show = computed(() => {
  if (props.alwaysShow) return true

  return (
    !userStore.isLoggedIn && (nwCookie.value != true || signedUp.value == true)
  )
})

function hide() {
  signedUp.value = true
}
</script>
